*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
form,
figure,
blockquote,
dl,
pre,
dd {
  margin: 0;
}

body {
  margin: 0;
  min-height: 100vh;
  word-wrap: break-word;
}

img,
picture,
video {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
}

input,
button,
textarea,
select {
  appearance: none;
  font: inherit;
  border: none;
  background: transparent;
  padding: 0;
  font-weight: 400;
}

img:not([alt]) {
  filter: blur(10px);
}

a {
  text-decoration: none;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

:root {
  --colors-gray1: hsl(0, 0%, 6%);
  --colors-gray2: hsl(0, 0%, 10%);
  --colors-gray3: hsl(0, 0%, 13%);
  --colors-gray4: hsl(0, 0%, 16%);
  --colors-gray5: hsl(0, 0%, 18%);
  --colors-gray6: hsl(0, 0%, 21%);
  --colors-gray7: hsl(0, 0%, 26%);
  --colors-gray8: hsl(0, 0%, 31%);
  --colors-gray9: hsl(0, 0%, 44%);
  --colors-gray10: hsl(0, 0%, 68%);
  --colors-gray11: hsl(0, 0%, 83%);
  --colors-gray12: hsl(0, 0%, 93%);
  --colors-bg: var(--colors-gray1);
  --colors-border: var(--colors-gray7);
  --colors-hiContrast: var(--colors-gray11);
  --colors-loContrast: var(--colors-gray10);
  --colors-accent: #00daef;

  --space-xl: calc(7rem + 5vmax);
  --space-lg: calc(5rem + 4vmax);
  --space-md: calc(3rem + 3vmax);
  --space-sm: calc(0.5rem + 1vmax);
  --space-xs: 0.35rem;
  --gutter: calc(8px + 1.2vw);
  --font: "Peridot", sans-serif;
  --font-mono: "Monolisa", monospace !important;

  /* leva overrides */
  --leva-colors-elevation2: var(--colors-bg) !important;
  --leva-colors-elevation3: var(--colors-gray4) !important;
  --leva-colors-accent1: var(--colors-accent) !important;
  --leva-colors-accent2: var(--colors-accent) !important;
  --leva-colors-accent3: var(--colors-accent) !important;
  --leva-colors-highlight3: var(--colors-gray1) !important;
  --leva-fonts-mono: "Monolisa", monospace !important;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  color: var(--colors-loContrast);
  background-color: var(--colors-bg);
}

html,
body {
  line-height: 1.6;
  font-size: 16px;
  font-family: var(--font);
  font-feature-settings: "ss01";
}

h1 {
  font-size: calc(20px);
  font-weight: 600;
  line-height: 1;
  color: var(--colors-hiContrast);
}

h2 {
  font-size: 18px;
  font-weight: 600;
  color: var(--colors-hiContrast);
}

#root {
  flex: 1;
  padding: var(--space-sm) var(--gutter) var(--space-xl) var(--gutter);
  width: auto;
  max-width: 60rem;
  margin: 0 auto 0 auto;
}

#header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-lg);
  padding-top: var(--space-sm);
}

#header:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  top: 0;
  background-color: var(--colors-border);
  animation: slide 1s cubic-bezier(0.16, 1, 0.3, 1);
  transform-origin: 0 0;
}
@keyframes slide {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

#header .links {
  display: flex;
  gap: var(--space-sm);
  align-items: center;
  justify-items: flex-end;
}

#header .links a {
  font-family: var(--font-mono);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  padding: 2px;
}

#header .links a svg {
  display: block;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: var(--colors-hiContrast);
  position: relative;
  overflow: visible;
}

a:before {
  position: absolute;
  top: 0;
  left: -0.5rem;
  content: " ";
  display: block;
  width: calc(100% + 1rem);
}

a:focus-visible {
  outline: 1px solid var(--colors-accent);
  outline-offset: 3px;
}

a:focus,
a:hover {
  color: var(--colors-accent);
}

.prose > * {
  max-width: 38rem;
  margin-bottom: var(--space-md);
}

.prose > p {
  margin-bottom: var(--space-sm);
}

.prose h2 {
  display: flex;
  position: relative;
  margin-top: var(--space-lg);
  margin-bottom: calc(var(--space-sm) - 4px);
  padding-bottom: var(--space-xs);
  /* border-bottom: 1px solid var(--colors-border); */
}

.prose h2:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  top: 100%;
  background-color: var(--colors-border);
  animation: slide 1s cubic-bezier(0.16, 1, 0.3, 1);
  transform-origin: 0 0;
}

li {
  margin: calc(var(--space-sm) * 2) 0;
}

.prose > p:first-of-type {
  margin-top: 0;
}
.prose > *:last-child {
  margin-bottom: 0;
}
